<template>
	<div class="FM_footerBox">
		<div class="container">
			<div class="row">
				<div class="col-md-2 align-self-center">
					<router-link to="/" class="FM_footerLogo float-left">
						<img src="~@/assets/logo/logo-h.png" class="img-logo hidden-xs hidden-sm" width="100%" alt="FOMO FUND">
						<img src="~@/assets/logo/logo-h.png" class="img-logo hidden-md hidden-lg hidden-xl" height="35px" alt="FOMO FUND">
					</router-link>
				</div>
				<div class="col-md-10 align-self-center">
					<p class="text-title">QUICK LINKS</p>
					<router-link to="/account/changePassword" class="FM_link-footer">
						<span class="mdi mdi-lock-reset"></span>
						Change Password
					</router-link>
<!--					<router-link to="/account/resendVerification" class="FM_link-footer">-->
<!--						<span class="mdi mdi-email-send-outline"></span>-->
<!--						Resend Verification Email-->
<!--					</router-link>-->
<!--					<router-link to="/account/changeEmail" class="FM_link-footer">-->
<!--						<span class="mdi mdi-email-edit-outline"></span>-->
<!--						Change Email-->
<!--					</router-link>-->
					<router-link to="/support" class="FM_link-footer">
						<span class="mdi mdi-frequently-asked-questions"></span>
						Support
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Footer"
	}
</script>

<style scoped>

</style>